import { decode } from '@msgpack/msgpack/dist/decode';


function hex2a(hexStr) {
  let arr = [];

  for (let i = 0; i < hexStr.length; i += 2) {
    arr.push(
      parseInt(hexStr.substr(i, 2), 16)
    );
  }

  return arr;
}


// decode msgpack vars from window object
export const vars = (window.wonderscore) ?
  decode(
    hex2a(
      window.wonderscore
    )
  ) :
  {};
