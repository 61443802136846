// component-specific constants (for lazy-load components)


// Chart
export const CHART__CLASS_BASE = 'c-chart';


// ImageCarousel
export const IMAGE_CAROUSEL__CLASS_BASE = 'c-image-carousel';


// ImageGrid
export const IMAGE_GRID__CLASS_BASE = 'c-image-grid';

export const IMAGE_GRID__MODE_SELECT = 'select';
export const IMAGE_GRID__MODE_DISPLAY = 'display';
export const IMAGE_GRID__MODE_REORDERABLE = 'reorderable';


// Selectable
export const SELECTABLE__CLASS_BASE = 'c-selectable';
export const SELECTABLE__LIST_ITEM_CHECKED = `${SELECTABLE__CLASS_BASE}__list-item ${SELECTABLE__CLASS_BASE}__list-item--checked`;


// SidePanel
export const SIDE_PANEL__CLASS_BASE = 'c-side-panel';


// Table
export const TABLE__CLASS_BASE = 'c-table';


// TableCustom
export const TABLE_CUSTOM__CLASS_BASE = 'c-table-custom';


// TooltipButton
export const TOOLTIP_BUTTON__CLASS_BASE = 'c-tooltip-button';


// VideoPlayer
export const VIDEO_PLAYER__CLASS_BASE = 'c-video-player';

export const VIDEO_PLAYER__STATUS_PLAYING = 'playing';
export const VIDEO_PLAYER__STATUS_PAUSED = 'paused';
export const VIDEO_PLAYER__STATUS_ERRORED = 'errored';

export const VIDEO_PLAYER__CONTEXT_FULL = 'full';
export const VIDEO_PLAYER__CONTEXT_PREVIEW = 'preview';
