import { vars } from './vars';


// general i18n
export const i18n = {
  'en-GB': {
    'euiSelectable.loadingOptions': 'Loading items',
    'euiSelectable.noAvailableOptions': 'No items available',
    'euiSelectable.noMatchingOptions': '{searchValue} doesn\'t match any items',

    'euiForm.addressFormErrors': 'Please fix the form errors',
  },
};


// context-specific i18n
export const i18n_clients = {
  'en-GB': {
    'euiSelectable.loadingOptions': 'Loading clients',
    'euiSelectable.noAvailableOptions': 'No clients available',
    'euiSelectable.noMatchingOptions': '{searchValue} doesn\'t match any clients',
  },
};

export const i18n_directors = {
  'en-GB': {
    'euiSelectable.loadingOptions': 'Loading directors',
    'euiSelectable.noAvailableOptions': 'No directors available',
    'euiSelectable.noMatchingOptions': '{searchValue} doesn\'t match any directors',
  },
};

export const i18n_presentation_details = {
  'en-GB': {
    'euiComboBoxOptionsList.allOptionsSelected': `You've selected all suggested email addresses`,
  },
};


// Optimization
// NOTE: check when adding new strings that this is still valid (en === en-GB)
i18n['en'] = i18n['en-GB'];
i18n_clients['en'] = i18n_clients['en-GB'];
i18n_directors['en'] = i18n_directors['en-GB'];
i18n_presentation_details['en'] = i18n_presentation_details['en-GB'];


export function getUserI18nMap(userLocale, localeStrings = i18n) {
  // set i18n strings via fallback cascade
  let i18nStringsOverride = {};

  if (userLocale) {
    if (localeStrings[userLocale]) {
      i18nStringsOverride = localeStrings[userLocale]

    } else {
      const userLocaleBase = userLocale.split(/[-_]+/)?.[0];

      if (userLocaleBase && localeStrings[userLocaleBase]) {
        i18nStringsOverride = localeStrings[userLocaleBase];

      } else if (localeStrings[vars.settings.locale]) {
        i18nStringsOverride = localeStrings[vars.settings.locale];
      }
    }
  }

  return i18nStringsOverride;
}


// return full object that EuiContext i18n prop expects
export function getUserI18nMapFull(userLocale, localeStrings = i18n) {
  return {
    mapping: getUserI18nMap(userLocale, localeStrings),
  };
}
