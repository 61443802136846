export const USER_ROLE_VENDOR = 'vendor';
export const USER_ROLE_SUPERADMIN = 'superadmin';
export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_STAFF = 'staff';
export const USER_ROLE_PRODUCER = 'producer';
export const USER_ROLE_DELETED = 'deleted';     // special role for users who have been 'deleted' (so that we don't break database references)


// user roles
export const USER_ROLE_PRECEDENCE = {
  [USER_ROLE_VENDOR]: 0,

  [USER_ROLE_SUPERADMIN]: 1,
  [USER_ROLE_ADMIN]: 2,
  [USER_ROLE_STAFF]: 3,
  [USER_ROLE_PRODUCER]: 4,

  [USER_ROLE_DELETED]: 999,
};

export const USER_ROLE_MAP = {
  [USER_ROLE_VENDOR]: 'Vendor',

  [USER_ROLE_SUPERADMIN]: 'SuperAdmin',
  [USER_ROLE_ADMIN]: 'Admin',
  [USER_ROLE_STAFF]: 'Staff',
  [USER_ROLE_PRODUCER]: 'Producer',

  [USER_ROLE_DELETED]: 'Deleted',
};

export const USER_ROLE_COLORS = {
  [USER_ROLE_VENDOR]: 'default',

  [USER_ROLE_SUPERADMIN]: 'accent',
  [USER_ROLE_ADMIN]: 'danger',
  [USER_ROLE_STAFF]: 'warning',
  [USER_ROLE_PRODUCER]: 'secondary',

  [USER_ROLE_DELETED]: 'hollow',
};

export const USER_ROLES = [
  {
    id: USER_ROLE_SUPERADMIN,
    label: USER_ROLE_MAP[USER_ROLE_SUPERADMIN],
    description: 'Everything, including deleting users and changing system settings',
  },
  {
    id: USER_ROLE_ADMIN,
    label: USER_ROLE_MAP[USER_ROLE_ADMIN],
    description: 'Everything, except delete users or change system settings',
  },
  {
    id: USER_ROLE_STAFF,
    label: USER_ROLE_MAP[USER_ROLE_STAFF],
    description: 'Can view, create, edit, delete all data types (except users)',
  },
  {
    id: USER_ROLE_PRODUCER,
    label: USER_ROLE_MAP[USER_ROLE_PRODUCER],
    description: 'Can view, create, edit all data types (except users)',
  },
];
