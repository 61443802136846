/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { Component } from 'react';

type EventNames = keyof WindowEventMap;

interface Props<Ev extends EventNames> {
  event: Ev;
  handler: (this: Window, ev: WindowEventMap[Ev]) => any;
}

export class EuiWindowEvent<E extends EventNames> extends Component<Props<E>> {
  componentDidMount() {
    this.addEvent(this.props);
  }

  componentDidUpdate(prevProps: Props<E>) {
    if (
      prevProps.event !== this.props.event ||
      prevProps.handler !== this.props.handler
    ) {
      this.removeEvent(prevProps);
      this.addEvent(this.props);
    }
  }

  componentWillUnmount() {
    this.removeEvent(this.props);
  }

  addEvent<Ev extends EventNames>({ event, handler }: Props<Ev>) {
    window.addEventListener(event, handler);
  }

  removeEvent<Ev extends EventNames>({ event, handler }: Props<Ev>) {
    window.removeEventListener(event, handler);
  }

  render() {
    return null;
  }
}
