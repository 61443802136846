import React from 'react';

import { withAppContext } from '../../context';

import LoadingSpinner from '../../components/loading-spinner';


class GlobalLoadingSpinner extends React.Component {
  render() {
    return (
      <LoadingSpinner
        isVisible={this.props.context.isGlobalLoading}
        obscure={true}
      />
    );
  }
}


export default withAppContext(GlobalLoadingSpinner);
