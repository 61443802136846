import React from 'react';

import { encode } from '@msgpack/msgpack/dist/encode';
import { decode } from '@msgpack/msgpack/dist/decode';

import { vars } from './vars';

import {
  USER_ROLE_PRECEDENCE,
} from './constants-user';
import {
  SELECTABLE__LIST_ITEM_CHECKED,
} from './constants-components';


// local variable to hold cached GET requests
const fetchCache_GET = {};


// local timer variable for throttle / debounce functions below
let timerId;


// MUST: this seems to be broken on live due to TrustedScript CSP, investigate / fix
export function debounce(func, delay = 100) {
  clearTimeout(timerId);

  timerId = setTimeout(func, delay);
}


export function isEmpty(obj) {
  return (
    (Object.keys(obj).length === 0) &&
    (obj.constructor === Object)
  );
}


export function clearCacheItem(endpoint) {
  delete fetchCache_GET[endpoint];
}


export function adaptiveFetch(
  {
    method,
    endpoint,
    data = {},
    headers = {},
    signal = undefined,
    cacheSeconds = null,
    clearCache = [],
    callbackIfForbidden = undefined,
  }
) {
  // check for cached data before request?
  let currentTime;
  let useCache = false;

  if (cacheSeconds && (method === 'GET') && isEmpty(data) && isEmpty(headers)) {
    currentTime = Date.now();
    useCache = true;

    // if data is cached and not yet expired, return immediately from cache
    if (fetchCache_GET[endpoint]?.t > (currentTime - (cacheSeconds * 1000))) {
      return Promise.resolve(
        fetchCache_GET[endpoint].v
      );
    }
  }

  // cache check not requested / data is not in cache...
  const MIMETYPE_JSON = 'application/json';
  const MIMETYPE_MSGPACK = 'application/x-msgpack';

  const {
    api_msgpack,
  } = vars.settings;

  let settings = {
    method: method,
    headers: {
      ...headers,

      // request
      'Content-Type': api_msgpack ?
        MIMETYPE_MSGPACK :
        MIMETYPE_JSON,

      // response
      'Accept': api_msgpack ?
        MIMETYPE_MSGPACK :
        MIMETYPE_JSON,
    },
    signal: signal,
  };

  if (!isEmpty(data)) {
    settings.body = api_msgpack ?
      encode(data) :
      JSON.stringify(data);
  }

  return fetch(
    endpoint,
    settings,
  )
    .then(
      (response) => {
        if (!response.ok) {
          const err = new Error(response.statusText);

          err.status = response.status;
          err.data = response.json();

          // if response is 403, and callbackIfForbidden is specified, run it
          if (
            (response.status === 403) &&
            (typeof callbackIfForbidden === 'function')
          ) {
            setTimeout(
              callbackIfForbidden,
              0,
            );
          }

          throw err;
        }

        return response;
      }
    )
    .then(
      async (response) => {
        // decode msgpack / JSON response data for consumption
        const contentType = response.headers.get('content-type');

        let data;

        if (contentType && contentType.indexOf(MIMETYPE_JSON) !== -1) {
          data = await response.json();

        } else {
          data = await response.arrayBuffer().then(
            (buffer) => decode(buffer)
          );
        }

        // cache data?
        if (useCache) {
          fetchCache_GET[endpoint] = {
            t: currentTime,
            v: data,
          }
        }

        // clear cache item(s)?
        if (clearCache.length > 0) {
          for (let i in clearCache) {
            clearCacheItem(
              clearCache[i]
            );
          }
        }

        return data;
      }
    );
}


export function mailtoLinkHref(address, subject, body) {
  return (
    `mailto:${address}${subject ? `?subject=${encodeURIComponent(subject)}` : ''}${body ? `&body=${encodeURIComponent(body)}` : ''}`
  );
}


export function canUseWebP() {
  if (!vars.settings.use_webp) {
    return false;
  }

  const elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return (elem.toDataURL('image/webp').indexOf('data:image/webp') === 0);
  }

  // very old browser like IE 8, canvas not supported
  return false;
}


export function isObject(item) {
  return (item && (typeof item === 'object') && !Array.isArray(item));
}


export function isArray(item) {
  return (item && (typeof item === 'object') && Array.isArray(item));
}


export function isMomentObject(item) {
  return (item && (typeof item === 'object') && (item._isAMomentObject === true));
}


export function deepMerge(target, ...sources) {
  if (!sources.length) {
    return target;
  }

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (!source.hasOwnProperty(key)) {
        continue;
      }

      if (isObject(source[key])) {
        // special-case Moment date objects, since we need their Object representation to remain intact
        if (isMomentObject(source[key])) {
          Object.assign(
            target,
            {
              [key]: source[key]
            }
          );

          continue;
        }

        if (!target[key]) {
          Object.assign(
            target,
            {
              [key]: {}
            }
          );
        }

        deepMerge(target[key], source[key]);

      } else {
        Object.assign(
          target,
          {
            [key]: source[key]
          }
        );
      }
    }
  }

  return deepMerge(target, ...sources);
}


export function deepConvert(obj) {
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }

    if (isObject(obj[key])) {
      if (isMomentObject(obj[key])) {
        // convert Moment date objects to a JSON string representation (converted to UTC)
        obj[key] = obj[key].toJSON();

      } else {
        obj[key] = deepConvert(obj[key]);
      }
    }
  }

  return obj;
}


export function setSelectableItemSelected(items, selectedItemId) {
  for (let i = 0, len = items.length; i < len; i++) {
    if (selectedItemId && (items[i].id === selectedItemId)) {
      items[i].checked = 'on';
      items[i].className = SELECTABLE__LIST_ITEM_CHECKED;

    } else {
      if (items[i].checked) {
        delete items[i].checked;
        delete items[i].className;

        // if we are using this function to unset the checked state of all items,
        // take advantage of the list being mutually-exclusing, and stop when the first
        // item has been unset
        if (!selectedItemId) {
          break;
        }
      }
    }
  }

  return items;
}


export function resolveTableColumns(columns) {
  // lazy-resolve column definitions...
  if (typeof columns === 'object') {
    for (let i in columns) {
      if (!columns.hasOwnProperty(i)) {
        continue;
      }

      if (typeof columns[i] === 'function') {
        // resolve column defined as a function to the required object
        columns[i] = columns[i].call(this);

      } else if (columns[i].actions) {
        // resolve Actions defined as a function to the required ReactNode type
        for (let j in columns[i].actions) {
          if (typeof columns[i].actions[j] === 'function') {
            columns[i].actions[j] = columns[i].actions[j].call(this);
          }
        }

        // remove any actions that have resolved as undefined (ie. when they are dynamically visible based on User role)
        columns[i].actions = columns[i].actions.filter(action => (action !== undefined));
      }
    }
  }

  // filter out any undefined items
  return columns.filter(
    (column) => (column !== undefined)
  );
}


export function formatNames(names) {
  const finalName = names.pop();

  return (names.length > 0)
    ? `${names.join(', ')} & ${finalName}`
    : finalName || '';
}
