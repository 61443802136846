import React from 'react';

import classNames from 'classnames';

import style from './style.scss';


// component-specific constants
export const LOADING_SPINNER__CLASS_BASE = 'c-loading-spinner';


class LoadingSpinner extends React.Component {
  classBase = LOADING_SPINNER__CLASS_BASE;

  render() {
    const {
      obscure,
      size,
      isVisible,
    } = this.props;

    // define conditional CSS classes
    const cssClasses = classNames(
      this.classBase,
      {
        [`${this.classBase}--obscure`]: obscure,
        [`${this.classBase}--${size}`]: size,

        'is-visible': isVisible,
      },
    );

    return (
      <div className={cssClasses}>
        <svg
          className={`${this.classBase}__image`}
          viewBox='0 0 187.3 93.7'
          preserveAspectRatio='xMidYMid meet'
        >
          <path
            className={`${this.classBase}__image-outline`}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z'
          />
          <path
            className={`${this.classBase}__image-outline-bg`}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z'
          />
        </svg>
      </div>
    );
  }
}


export default LoadingSpinner;
